<script>
export default {
    components: {
        'mt-button': app.Mint.Button,
    },
    data() {
        return {
            bool: false,
            monryResult: false,
            money: '',
        }
    },
    inject:['reload'],
    methods: {
        close() {
            this.bool = false;
            this.reload()
        }
    },
    mounted() {

    },
    computed:{
        language(){
            return window.language
        }
    }
}
</script>
<template>
    <div id="success" v-if="bool" :class="_TEMPLATE">
        <div class="popup">
            <div class="inner" :class="{ success:monryResult===false, 'out-of-point':monryResult==='outOfPoint' }">
                <template v-if="monryResult===false">
                    <span class="position1">{{ getWord('congratulations') }}</span>
                    <span class="position2"><b>{{money}}</b>{{ getWord('newbie_prize') }}</span>
                    <span class="close" @click.stop="close()">{{ getWord('get_it') }}</span>
                </template>
                <template v-else>
                    <span class="close" @click.stop="close()"></span>
                </template>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
#success {
    background-color:#D0D0D0;

    .popup {
        background-color: rgba(0,0,0,.3);
        position:fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: 11;

        .inner {
            background-image: url('/static/assets/images/luckydraw/failed.png');
            height: 6.7rem;
            top: 3.2rem;             
            position: absolute;            
            left: 0;
            width: 7.5rem;
            background-size: contain;
            right: 0;
            margin: auto;
            background-repeat: no-repeat;
            background-position: center;
            text-align: center;

            &.out-of-point {
                background-image: url('/static/assets/images/luckydraw/out-of-point.png');

                span {

                    &.close {
                        top: 4.7rem;
                    }
                }
            }

            &.success {                
                top: 1rem;
                height: 7.8rem;
                background-image: url('/static/assets/images/luckydraw/success.png');

                span {

                    &.close {
                        background-color: #FFDC50;
                        top: 6.4rem;
                        width: auto;
                        height: auto;
                    }
                }
            }
            
            span {
                position:absolute;
                left: 0;
                right: 0;

                &.position1 {
                    color: #C25400;
                    font-size: .36rem;
                    top: 3.4rem;
                }

                &.position2 {
                    color: #C25400;
                    font-size: .24rem;
                    top: 4rem;

                    b {
                        display: block;
                        font-weight: normal;
                        font-size: .58rem;
                        color: #D10001;
                    }
                }

                &.close {
                    width: 2.4rem;
                    height: 0.7rem;
                    top: 5.5rem;
                    background-color: transparent;                    
                    font-size: .36rem;
                    color: #C25400;
                    left: auto;
                    right: auto;
                    margin: 0 auto;
                    transform: translateX(-50%);                    
                    padding: .16rem .75rem;
                    border-radius: 1rem;
                }
            }
        }
    }
}

.myposition {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 888;
    background: #000;
    opacity: 0.5;
}

.main_no {
    position: absolute;
    width: 6.31rem;
    height: 5.8rem;
    left: 0.6rem;
    top: 4rem;
    background-image: url('/static/assets/images/luckydraw/out-of-point.png');
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 100%;
    z-index: 999;
}

.main_yes {
    position: absolute;
    width: 5.74rem;
    height: 5.84rem;
    left: 0.8rem;
    top: 4rem;
    background-image: url('/static/assets/images2/luck/zhojiang.png');
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 100%;
    z-index: 999;
}

.but_css_no {
    float: left;
    width: 3rem;
    height: 0.87rem;
    border-radius: 0.4rem;
    color: rgba(247, 41, 5, 1);
    font-size: 0.36rem;
    margin: 3.7rem 1.6rem;
}

.but_css_yes {
    float: left;
    width: 3rem;
    height: 0.87rem;
    border-radius: 0.4rem;
    color: rgba(247, 41, 5, 1);
    font-size: 0.36rem;
    margin: 0.7rem 1.4rem;
}

.money_e {
    float: left;
    width: 2rem;
    height: 0.47rem;
    margin: 1.7rem 1.7rem;
    font-size: 0.6rem;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: rgba(236, 35, 7, 1);
    text-align: center;
}
</style>
<script>
import getResult from "@/views/action/getResult";
import { drawPrepare } from "@/api/home";
import { getMoneyResult } from "@/api/home";
export default {
	name: "LuckyDraw",
	components: {
		'mt-header': app.Mint.Header,
        'mt-button': app.Mint.Button,
        Header: () => import('components/base/Header'),
        getResult: getResult,
    },
	data() {
		return {
			//抽奖每次积分
			integralEachTime: "",
			//积分
			integral: "",
			//奖项金额（浮点数数组）
			integralItems: [],
			rotate: "",
			length: "",

			header:{
                mainTitle:getWord('lucky_draw'),
            },
		};
	},
	methods: {
		left_click() {
			let hisNum = parseInt(window.history.length);
			if (hisNum == 1) {
				this.$router.push("/home");
			} else {
				this.$router.go(-1);
			}
		},
		//抽奖结果
		getMoneyResult() {
			//旋转罗盘，抽奖
			if (parseInt(this.integral)>=parseInt(this.integralEachTime)) {
				getMoneyResult().then(result => {
					if (result.data.code == "FAIL") {
						app.Mint.Toast(result.data.msg);
					} else if (result.data.code == "SUCCESS" && typeof result.data.result !== 'boolean') {
						for (var i = 0; i < this.length; i++) {
							if (this.integralItems[i] == result.data.result.money) {
								this.rotate = "rotate(" + (-i * 30 + 1440 + 45) + "deg)";
							}
						}
						if (Number(result.data.result.money) > 0) {
							this.$refs.getResult.first_flag = false;
							this.$refs.getResult.money = result.data.result.money;
						} else {
							this.$refs.getResult.first_flag = true;
							this.$refs.getResult.monryResult = true;
						}
						setTimeout(() => {
							this.$refs.getResult.bool = true;
						}, 3500);
					} else {
						this.rotate = "rotate(" + (0 * 30 + 1440 + 45) + "deg)";

						setTimeout(() => {
							this.$refs.getResult.bool = true;
							this.$refs.getResult.monryResult = true
						}, 3500);
					}
				});
			} else {
				this.$refs.getResult.bool = true;
				this.$refs.getResult.monryResult = 'outOfPoint'
			}
		},
		rule() {
			this.$router.push({
				path: "/action/lotteryRules?jf=" + this.integralEachTime
			});
		},
		mymoney() {
			this.$router.push({ path: "/action/withdrawalBonus" });
		}
	},
	mounted() {
		drawPrepare().then(result => {
			if (result.data.code == "FAIL") {
				app.Mint.Toast(result.data.msg);
			} else if (result.data.code == "SUCCESS") {
				this.integral = result.data.result.integral;
				this.integralEachTime = result.data.result.integralEachTime;
				this.integralItems = result.data.result.integralItems;
				this.length = this.integralItems.length;
			}
		});
		this.CustomScroll(this.$refs.main.id);
	},
	computed:{
        language(){
            return window.language
        }
    }
};
</script>
<template>
	<div class="main" id="main" ref="main" :class="_TEMPLATE">

		<template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
			<mt-header :title="getWord('lucky_draw')" style="height:0.88rem;font-size: 0.35rem;wdith:100%;">
				<mt-button icon="back" slot="left" @click="left_click()"></mt-button>
			</mt-header>
			<div class="background_color">
				<div class="header">{{ getWord('points_of_app') }}:{{integral}}({{integralEachTime}}{{ getWord('points_of_app') }}/{{ getWord('times') }}）</div>
				<div class="middle">
					 <!-- :style="{'transform': 'rotate('+(-45+i*30)+'deg)'}" -->
					<div class="middle_middle" :style="{'transform': rotate}">
						<span class="middle_div"
							v-for="(it,i) in integralItems" :key="i"
							:style="{'transform': 'rotate('+(-45+i*30)+'deg)'}">
							<span  v-if="it !=0.00">{{ currencySymbol() }}{{it}}</span>
							<span  v-if="it==0.00"></span>
						</span>
					</div>
					<div class="point" @click="getMoneyResult()"></div>
				</div>

				<div class="buttons">
				<mt-button type="danger" class="but_css" @click="rule()">{{ getWord('event_rules') }}</mt-button>
				<mt-button type="danger" class="but_css" @click="mymoney()">{{ getWord(['personal_centre2', 'bonus']) }}</mt-button>
			</div>
			</div>
			<div class="backBig"></div>
			
		</template>

		<template v-if="_TEMPLATE==='template-3'">
			<Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <main>
            	<header>
            		<span>{{ getWord('points_of_app') }}:{{integral}}({{integralEachTime}}{{ getWord('points_of_app') }}/{{ getWord('times') }}）</span>
            	</header>

            	<div id="draw">
					<ul class="circle" :style="{'transform': rotate}">
						<li v-for="(item,index) in integralItems" :key="index" :style="{'transform': 'rotate('+(-45+index*30)+'deg)'}">
							<span>{{ item.toString() !== '0.00'?currencySymbol()+item:'' }}</span>
						</li>
					</ul>
					<div class="pointer" @click="getMoneyResult()"></div>
				</div>

				<div class="buttons">
					<mt-button type="danger" class="but_css" @click="rule()">{{ getWord('lucky_draw_rules') }}</mt-button>
					<mt-button type="danger" class="but_css" @click="mymoney()">{{ getWord(['withdraw2', 'bonus']) }}</mt-button>
				</div>
            </main>
		</template>

		<getResult ref="getResult"></getResult>

	</div>
</template>
<style scoped lang='scss'>
#main {
	overflow: auto;

	&.template-3 {
		height: 100%;
	    display: flex;
		flex-direction: column;

		main {
			height: 100%;
		    background-color: #EC2829;

			header {
				position:relative;
				background-image: url('/static/assets/images/luckydraw/bg.png');
			    background-size: contain;
			    background-repeat: no-repeat;
		        background-position: center;
			    text-align: center;
			    height: 4.5rem;
			    margin-bottom: -1.4rem;

			    span {
			    	position:absolute;
			    	top: 1.75rem;
			    	color: #601986;
			    	font-size: .4rem;
			    	left: 0;
			    	right: 0;
		    	    font-weight: bold;
			    }
			}

			#draw {
				background-image: url('/static/assets/images/luckydraw/circle-wrap.png');
				width: 6.8rem;
			    height: 6.8rem;
			    margin: 0 auto;
				background-size: contain;
    			background-repeat: no-repeat;
    			position: relative;

    			&:after {
    				content:'';
    				background-image: url('/static/assets/images/luckydraw/shade.png');
    				height: .7rem;
    				display: block;
    				background-size: contain;
    				background-repeat:no-repeat;
				    background-position: center;
    				margin-top: 1rem;
    			}

    			.circle {
    				transition: 3.5s ease-in-out all;
    				width: 5.8rem;
    				height: 5.8rem;
    				background-image: url('/static/assets/images/luckydraw/circle.png');
    				background-size:contain;
				    position: relative;
					left: .5rem;
					top: .5rem;

					li {
						width: 1.2rem;
						height: 5.38rem;    
						position: absolute;
						list-style-type: none;
						top: .2rem;
					    left: 2.25rem;
					    text-align: center;
				        color: #ffcc00;
					}
    			}

    			.pointer {
    				position:absolute;
    				width: 2.46rem;
					height: 3.23rem;					
					background-image: url('/static/assets/images/luckydraw/pointer.png');
					background-repeat: no-repeat;
					background-size: contain;
					top: 1.4rem;
					left:2.18rem;
    			}
			}

			.buttons {
			    margin-top: 1rem;
		        display: flex;
				justify-content: space-evenly;

			    button {
			    	font-size: .3rem;
			    	color: #333333;
			    	background-color: #FFBE04;
			    	padding: .16rem .25rem;
				    height: auto;
				    margin: 0;
				    word-break: break-all;
			    }
			}
		}
	}
}
.backBig {
	position: fixed;
	height: 100%;
	width: 100%;
	background: #e4b421;
	bottom: 0;
	z-index: -999999;
}
.background_color {
	background-image: url('/static/assets/images2/luck/z5.png');
	height: 12.06rem;
    background-size: 100%;
    position: relative;
    padding-top: 50vw;
	background-repeat: no-repeat;

	button {
		width: auto;
		white-space: nowrap;
	}
}
.header {
	position: absolute;
    left: 0;
    right: 0;
    top: 41vw;
	text-align: center;
    color: #ffffff;
    font-size: .24rem;
}
.middle {
	margin: 0 auto;
	width: 6.78rem;
	height: 6.8rem;
	background-image: url('/static/assets/images2/luck/wq.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	margin-top: 0.4rem;
}
.middle_div {
	position: absolute;
	top: 0.2rem;
	left: 2.25rem;
	height: 5.38rem;
	width: 1.2rem;
	font-size: 0.2rem;
	font-weight: bold;
	text-align: center;
	color: #ffcc00;
}
.middle_middle {
	position:relative;
	width: 5.78rem;
	height: 5.78rem;
	top:0.5rem;
	left:0.52rem;
	background-image: url('/static/assets/images2/luck/yaojiangqu.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	transition: 3.5s ease-in-out;
}
.point {
	
	width: 2.46rem;
	height: 3.23rem;
	top:-4.3rem;
	left:2.18rem;
	background-image: url('/static/assets/images2/luck/zhizhen.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	position: relative;
}
.but_css {
	float: left;
	height: 0.84rem;
	width: 2.21rem;
	background-color: #c22919;
	margin: 0.2rem 0 0 1rem;
	font-size: 0.36rem;
	font-weight: 400;
	color: rgba(255, 255, 255, 1);
}

.buttons {
			    margin-top: 1rem;
		        display: flex;
				justify-content: space-evenly;

			    button {
			    	font-size: .3rem;
			    	padding: .16rem .25rem;
				    height: auto;
				    margin: 0;
				    word-break: break-all;
			    }
			}
</style>


